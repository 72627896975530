import { useState, useEffect } from "react";

import { useAPIUserInfo, useDataForPwa } from "hooks/useAPI";
import { useNavigate } from "react-router-dom";
import { setArticles } from "store/redux/articles";
import { setUserData, setOffline, setOnline } from "store/redux/basic";
import { setCodelists } from "store/redux/codelists";
import { setCustomers } from "store/redux/customers";

import { useAppDispatch } from "./useRedux";

const useAppInit = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loadUserInfo] = useAPIUserInfo();
  const [loadPwaData] = useDataForPwa();

  const [isUserDataReady, setIsUserDataReady] = useState(false);
  const [isPwaDataReady, setIsPwaDataReady] = useState(false);
  const [isReadyToRender, setIsReadyToRender] = useState(false);

  const getUserInfo = async () => {
    try {
      const { data } = await loadUserInfo();
      dispatch(setOnline());

      if (data && data.userInfo) dispatch(setUserData(data.userInfo));
    } catch (error) {
      dispatch(setOffline());
    } finally {
      setIsUserDataReady(true);
    }
  };

  const getPwaData = async () => {
    try {
      const { data } = await loadPwaData();

      if (data && data.dataForPwa) {
        const { suppliers, buyers, articles, warehouses, companyInfo, currencies, units } = data.dataForPwa;

        dispatch(setArticles(articles));
        dispatch(setCustomers({ suppliers, buyers }));
        dispatch(
          setCodelists({
            currencies,
            units,
            warehouses,
            companyInfo: JSON.parse(companyInfo.value),
          })
        );
      }
    } catch (error) {
    } finally {
      setIsPwaDataReady(true);
    }
  };

  const loadData = async () => {
    await getUserInfo();
    await getPwaData();
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (isUserDataReady && isPwaDataReady) {
      if (localStorage.getItem("token")) {
        setIsReadyToRender(true);
      } else {
        navigate("/login");
      }
    }
  }, [isUserDataReady, isPwaDataReady]);

  return {
    isReadyToRender,
    reaload: loadData,
  };
};

export default useAppInit;
