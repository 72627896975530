import React from "react";

import { Box, Stack, Typography, StackProps } from "@mui/material";
import { formatPostalCode } from "utils";

interface IProps {
  name: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  ico?: string;
  dic?: string;
  stackProps?: StackProps;
}

const CompanyItem: React.FC<IProps> = ({ name, address, city, postalCode, country, ico, dic, stackProps }) => {
  return (
    <Box>
      <Typography variant="subtitle1" fontWeight={700}>
        {name}
      </Typography>
      <Stack spacing={1} {...stackProps}>
        <Stack>
          <Typography variant="body1">{address}</Typography>
          <Typography variant="body1">{`${formatPostalCode(postalCode)} ${city}`}</Typography>
          <Typography variant="body1">{country}</Typography>
        </Stack>
        <Stack>
          {ico && <Typography variant="body1">{`IČO: ${ico}`}</Typography>}
          {dic && <Typography variant="body1">{`DIČ: ${dic}`}</Typography>}
        </Stack>
      </Stack>
    </Box>
  );
};

export default CompanyItem;
