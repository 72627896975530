import ModeNightRoundedIcon from "@mui/icons-material/ModeNightRounded";
import WbSunnyRoundedIcon from "@mui/icons-material/WbSunnyRounded";
import { PaletteMode, IconButton } from "@mui/material";

interface ToggleColorModeProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
}

export default function ToggleColorMode({
  mode,
  toggleColorMode,
}: ToggleColorModeProps) {
  return (
    <IconButton
      onClick={toggleColorMode}
      color="primary"
      aria-label="Theme toggle button"
    >
      {mode === "dark" ? (
        <WbSunnyRoundedIcon />
      ) : (
        <ModeNightRoundedIcon />
      )}
    </IconButton>
  );
}
