import { useEffect, useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  IconButton,
  MenuItem,
  Stack,
  Select as MUISelect,
  Typography,
  lighten,
  styled,
  darken,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import { DatePicker } from "@mui/x-date-pickers";
import BackdropLoading from "components/common/BackdropLoading";
import Modal from "components/common/Modal";
import Select from "components/common/Select";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { enqueueSnackbar } from "notistack";
import { articles as getArticles } from "store/redux/articles";
import { userData } from "store/redux/basic";
import { articlesByWarehouse } from "store/redux/codelists";
import { updateDocument } from "store/redux/documents";
import { IArticle, ICurrency, IDocument, IDocumentItems } from "types";
import { CustomerType, WDocumentType } from "types/enums";

import ItemInput from "./ItemInput";
import CompanyItem from "../AddDocumentModal/CompanyItem";
import CustomerSection from "../AddDocumentModal/CustomerSection";

interface IProps {
  handleClose: () => void;
  data: IDocument;
  index: number;
}

const DOC_DATA = {
  [WDocumentType.IN]: {
    title: "Upraviť skladovú príjemku",
    customer: "Dodávatel",
  },
  [WDocumentType.OUT]: {
    title: "Upraviť skladovú výdajku",
    customer: "Odberateľ",
  },
};

const SectionBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor:
    theme.palette.mode === "light" ? lighten(theme.palette.primary.main, 0.8) : darken(theme.palette.primary.main, 0.8),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignContent: "center",
}));

const getCurrencyName = (data: ICurrency[], currencyId?: number) => {
  if (!currencyId) return undefined;

  const currency = data.find((currency) => currency.id === currencyId);
  return currency?.description ?? currency?.name ?? undefined;
};

const EditDocumentModal = ({ handleClose, data, index }: IProps) => {
  const dispatch = useAppDispatch();
  const type = data.type === WDocumentType.IN ? WDocumentType.IN : WDocumentType.OUT;

  const user = useAppSelector(userData);
  const { companyInfo, currencies, warehouses } = useAppSelector((state) => state.codelists);

  const [loading, setLoading] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [articles, setArticles] = useState<IArticle[]>([]);

  const [createdAt, setCreatedAt] = useState<Date | null>(new Date(data.createdAt));
  const [note, setNote] = useState<string>(data.note ?? "");
  const [currencyId, setCurrencyId] = useState<number>(data.currencyId);
  const [items, setItems] = useState<IDocumentItems[]>(data.items);
  const [customerId, setCustomerId] = useState<number | undefined>(data.customerId);
  const [warehouseId, setWarehouseId] = useState<number | undefined>(data.warehouseId);
  const [comment, setComment] = useState<string>(data.comment ?? "");

  const currency = getCurrencyName(currencies, currencyId);
  const articlesData = useAppSelector(getArticles);
  const articlesByWarehouseData = useAppSelector((store) => articlesByWarehouse(store, warehouseId ?? -1));

  const fetchArticlesByWarehouse = async () => {
    if (articlesByWarehouseData && articlesByWarehouseData.length) {
      // if (warehouseId === data.warehouseId) {
      //   const newArticles = articlesByWarehouseData.map((article) => {
      //     const item = data.items.find((i) => i.articleId === article.id);
      //     if (item) return { ...article, quantity: article.quantity + (item.quantity ?? 0) };
      //     return article;
      //   });
      //   setArticles(newArticles);
      // } else setArticles(articlesByWarehouseData);
      setArticles(articlesByWarehouseData);
    } else setArticles([]);
  };

  useEffect(() => {
    if (warehouseId && type === WDocumentType.OUT && articlesByWarehouseData?.length) fetchArticlesByWarehouse();
  }, [warehouseId, type, articlesByWarehouseData]);

  useEffect(() => {
    if (articlesData && articlesData.length && type === WDocumentType.IN) {
      setArticles(articlesData);
    }
  }, [articlesData, type]);

  useEffect(() => {
    if (currencies && currencies.length > 0 && !currencyId) {
      setCurrencyId(currencies[0].id);
    }
  }, [currencies]);

  useEffect(() => {
    if (warehouseId && items.length) setIsInit(true);
  }, [warehouseId, items]);

  useEffect(() => {
    if (warehouseId && type === WDocumentType.OUT && isInit) setItems([]);
  }, [warehouseId]);

  const handleUpdate = async () => {
    if (!createdAt || !currencyId || !warehouseId || !items.length || !user?.id) return;
    setLoading(true);
    const price = items.reduce((acc, item) => acc + (item.price ?? 0) * (item.quantity ?? 0), 0);
    try {
      dispatch(
        updateDocument({
          index: index,
          document: {
            createdAt: createdAt.toISOString(),
            note,
            currencyId,
            warehouseId,
            items,
            type,
            userId: user?.id,
            customerId,
            price,
            comment,
          },
        })
      );
      handleClose();
      enqueueSnackbar(`${DOC_DATA[type].title} sa úspešne podarilo`, { variant: "success" });
    } catch (e: any) {
      enqueueSnackbar(`${DOC_DATA[type].title} sa nepodarilo`, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const getTotalPrice = () => {
    const totalPrice = items.reduce((acc, item) => acc + (item.price ?? 0) * (item.quantity ?? 0), 0);
    const value = totalPrice
      .toFixed(2)
      .replace(".", ",")
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    if (currency) return `${value} ${currency}`;
    return value;
  };

  const isPossibleToUpdate = () =>
    createdAt
    && currencyId
    && warehouseId
    && items.length > 0
    && items.every((item) => item.articleId !== -1 && (item.price ?? 0) > 0 && (item.quantity ?? 0) > 0);

  const handleAddItem = () => setItems((prev) => [...prev, { price: 0, quantity: 1, articleId: -1 }]);
  const handleRemoveItem = (index: number) => setItems((prev) => prev.filter((_, i) => i !== index));
  const handleItemChange = (index: number, data: IDocumentItems) => {
    setItems((prev) => {
      const newItems = [...prev];
      newItems[index] = data;
      return newItems;
    });
  };

  return (
    <Modal
      title={DOC_DATA[type].title}
      onConfirm={handleUpdate}
      disableConfirm={!isPossibleToUpdate()}
      confirmText="Uložiť"
      onClose={handleClose}>
      <Grid container spacing={2}>
        <Grid xs={12}>{companyInfo && <CompanyItem {...companyInfo} />}</Grid>
        <Grid xs={12}>
          <Select
            label="Sklad *"
            fullWidth
            value={warehouseId ?? ""}
            onChange={(e) => setWarehouseId(e.target.value as number)}
            disabled={warehouses.length === 0}>
            {warehouses.map((warehouse) => (
              <MenuItem key={warehouse.id} value={warehouse.id}>
                {warehouse.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid xs={12}>
          <DatePicker
            label="Vystavená dňa *"
            sx={{ width: "100%" }}
            value={createdAt}
            onChange={(newValue) => setCreatedAt(newValue)}
            timezone="Europe/Bratislava"
          />
        </Grid>
        <Grid xs={12}>
          <Stack rowGap={0.5}>
            <SectionBox width={"100%"} height={"100%"}>
              <Typography variant="subtitle1">Mena</Typography>
            </SectionBox>
            <MUISelect
              fullWidth
              value={currencyId ?? ""}
              disabled={currencies.length === 0}
              onChange={(e) => setCurrencyId(e.target.value as number)}>
              {currencies.map((currency) => (
                <MenuItem key={currency.id} value={currency.id}>
                  {currency.name}
                </MenuItem>
              ))}
            </MUISelect>
          </Stack>
        </Grid>
        <Grid xs={12}>
          <Stack rowGap={0.5}>
            <SectionBox width={"100%"} height={"100%"}>
              <Typography variant="subtitle1">{DOC_DATA[type].customer}</Typography>
            </SectionBox>
            <CustomerSection
              customerId={customerId}
              onChangeCustomerId={(id) => setCustomerId(id)}
              type={type === WDocumentType.OUT ? CustomerType.BUYER : CustomerType.SUPPLIER}
            />
          </Stack>
        </Grid>
        <Grid xs={12}>
          <TextField label="Poznámka" fullWidth value={note} onChange={(e) => setNote(e.target.value)} />
        </Grid>
        <Grid xs={12}>
          <Stack rowGap={1}>
            <SectionBox>
              <Typography variant="subtitle1" fontWeight={400}>
                Položky
              </Typography>
              <IconButton onClick={handleAddItem}>
                <AddCircleIcon />
              </IconButton>
            </SectionBox>
            <Stack rowGap={1}>
              {items.map((item, index) => (
                <ItemInput
                  key={`item-${index}`}
                  articles={articles}
                  data={item}
                  index={index}
                  onChange={handleItemChange}
                  onDelete={handleRemoveItem}
                  warehouseId={type === WDocumentType.OUT ? warehouseId ?? -1 : undefined}
                  selectedItems={items.map((item) => item.articleId ?? -1)}
                  isLast={index === items.length - 1}
                />
              ))}
            </Stack>
          </Stack>
        </Grid>
        <Grid xs={12}>
          <SectionBox width={{ xs: "100%", sm: "50%" }}>
            <Typography variant="subtitle1">Celkom k úhrade</Typography>
            <Typography variant="subtitle1">{getTotalPrice()}</Typography>
          </SectionBox>
        </Grid>
        <Grid xs={12}>
          <TextField label="Komentár" fullWidth value={comment} onChange={(e) => setComment(e.target.value)} />
        </Grid>
        <Grid xs={12}>
          <Typography variant="subtitle1" fontWeight={700}>
            Vystavil:
          </Typography>
          <Typography variant="subtitle1" color={"text.secondary"}>{`${user?.name} ${user?.surname}`}</Typography>
          <Typography variant="subtitle1" color={"text.secondary"}>
            {user?.email}
          </Typography>
        </Grid>
      </Grid>
      <BackdropLoading loading={loading} />
    </Modal>
  );
};

export default EditDocumentModal;
