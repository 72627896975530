import { useEffect, useState } from "react";

import WarehouseIcon from '@mui/icons-material/Store';
import { Avatar, Card, CardContent, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import Modal from "components/common/Modal";
import { useAppSelector } from "hooks/useRedux";
import { articles } from "store/redux/articles";

interface IProps {
  handleClose: () => void;
  id: number;
}

const ArticleDetailModal = ({ handleClose, id }: IProps) => {
  const { warehouses } = useAppSelector((state) => state.codelists);
  const articlesData = useAppSelector(articles);
  const article = articlesData?.find((article) => article.id === id);

  const [availableWarehouses, setAvailableWarehouses] = useState<{ name: string; quantity: number; }[]>([]);

  useEffect(() => {
    if (id && warehouses.length) {
      const availableWarehouses = warehouses.filter((warehouse) =>
        warehouse.articles.find((article) => article.id === id && article.quantity > 0)
      ).map((warehouse) => ({
        name: warehouse.name,
        quantity: warehouse.articles.find((article) => article.id === id)?.quantity ?? 0
      }));

      setAvailableWarehouses(availableWarehouses);
    }
  }, [id, warehouses]);

  const getQuantityText = (quantity: number) => {
    const lastDigit = quantity % 10;
    const secondLastDigit = Math.floor((quantity % 100) / 10);
    if (lastDigit === 1 && secondLastDigit !== 1) {
      return `${quantity} kus`;
    } else if (lastDigit >= 2 && lastDigit <= 4 && (secondLastDigit !== 1)) {
      return `${quantity} kusy`;
    } else {
      return `${quantity} kusov`;
    }
  };

  return (
    <Modal title={article?.name ?? ""} onClose={handleClose}>
      <div>
        <Typography variant="h6" gutterBottom component="h2">
          Dostupnosť tovaru na skladoch:
        </Typography>
        {availableWarehouses.length > 0 ? (
          <List>
            {availableWarehouses.map((warehouse, index) => (
              <Card key={index} variant="outlined" sx={{ mb: 2 }}>
                <CardContent>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <WarehouseIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`${warehouse.name}: ${getQuantityText(warehouse.quantity)}`} />
                  </ListItem>
                </CardContent>
              </Card>
            ))}
          </List>
        ) : (
          <Typography variant="subtitle1" gutterBottom>
            Tovar nie je dostupný na žiadnom sklade.
          </Typography>
        )}
      </div>
    </Modal>
  );
};

export default ArticleDetailModal;
