import React, { useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ArticleDetailModal from "components/modules/ArticleDetailModal";
import { useModalContext } from "context/ModalContext";
import { useAppSelector } from "hooks/useRedux";
import { articles } from "store/redux/articles";

const PriceList: React.FC = () => {
  const { showModal, closeModal } = useModalContext();
  const [search, setSearch] = useState("");
  const articlesList = useAppSelector(articles).filter((article) =>
    article.name.toLowerCase().includes(search.toLowerCase())
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleDetail = (id: number) => {
    showModal(<ArticleDetailModal handleClose={closeModal} id={id} />);
  };

  return (
    <Box pt={2}>
      <TextField
        fullWidth
        variant="outlined"
        label="Vyhladať"
        value={search}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <List>
        <Divider variant="middle" />
        {articlesList.map((article) => (
          <React.Fragment key={article.id}>
            <ListItem onClick={() => handleDetail(article.id)}>
              <ListItemText
                primary={
                  <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography fontWeight={600} variant="body1">
                      {`[${article.id}] ${article.name}`}
                    </Typography>
                    <Typography
                      fontWeight={600}
                      variant="body1">{`${article.price} ${article.currency.description ?? article.currency.name}`}</Typography>
                  </Stack>
                }
                secondary={
                  <>
                    <Typography variant="body2" color="textPrimary">
                      {`Značka: ${article.category.name} | Typ: ${article.subcategory.name}`}
                    </Typography>
                    <Typography variant="caption">
                      {`Výroba: ${article.manufacturer} | Poznámka: ${article.note}`}
                    </Typography>
                  </>
                }
              />
            </ListItem>
            <Divider variant="middle" />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default PriceList;
