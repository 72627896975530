import { forwardRef } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography, Slide, AppBar, Toolbar, DialogContent } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { TransitionProps } from "@mui/material/transitions";

interface IProps {
  children: React.ReactNode;
  onClose?: () => void;
  title: string;
  onConfirm?: () => void;
  confirmText?: string;
  disableConfirm?: boolean;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = ({ children, onClose, onConfirm, title, confirmText, disableConfirm }: IProps) => {
  return (
    <Dialog open={true} fullScreen TransitionComponent={Transition}>
      <AppBar sx={{ position: "fixed" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>
          {onConfirm && (
            <Button onClick={onConfirm} color="inherit" disabled={disableConfirm}>
              {confirmText ?? "Pridať"}
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Toolbar />
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
