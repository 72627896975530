import { gql, useMutation } from "@apollo/client";
import { IInventoryDocumentInput, IDocument, IPwaData, IUserData, LoginInput, LoginResult } from "types";

const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(loginInput: $input) {
      access_token
      username
      email
      name
      surname
      id
      role
    }
  }
`;
export function useAPILogin() {
  return useMutation<{ login: LoginResult }, { input: LoginInput }>(LOGIN);
}

const USER_INFO = gql`
  mutation UserInfo {
    userInfo {
      username
      email
      name
      surname
      id
      role
    }
  }
`;

export function useAPIUserInfo() {
  return useMutation<{ userInfo: IUserData }>(USER_INFO);
}

const DATA_FOR_PWA = gql`
  mutation dataForPwa {
    dataForPwa {
      articles {
        category {
          name
        }
        currency {
          description
          name
        }
        id
        manufacturer
        minStock
        name
        note
        price
        priceVat
        quantity
        subcategory {
          name
        }
        unit {
          name
        }
      }
      buyers {
        address
        city
        country
        dic
        email
        ico
        id
        name
        note
        phone
        postalCode
        type
      }
      companyInfo {
        id
        value
      }
      currencies {
        description
        id
        name
      }
      suppliers {
        address
        city
        country
        dic
        email
        ico
        id
        name
        note
        phone
        postalCode
        type
      }
      units {
        id
        name
      }
      warehouses {
        id
        name
        articles {
          category {
            name
          }
          currency {
            description
            name
          }
          id
          manufacturer
          minStock
          name
          note
          price
          priceVat
          quantity
          subcategory {
            name
          }
          unit {
            name
          }
        }
      }
    }
  }
`;

export function useDataForPwa() {
  return useMutation<{ dataForPwa: IPwaData }>(DATA_FOR_PWA);
}

const CREATE_MOBILE_WAREHOUSE_DOCUMENT = gql`
  mutation CreateMobileWarehouseDocument($createMobileWarehouseDocumentInput: CreateMobileWarehouseDocumentInput!) {
    createMobileWarehouseDocument(createMobileWarehouseDocumentInput: $createMobileWarehouseDocumentInput) {
      id
    }
  }
`;

export function useCreateMobileWarehouseDocument() {
  return useMutation<
    { createMobileWarehouseDocument: { id: string } },
    { createMobileWarehouseDocumentInput: IDocument }
  >(CREATE_MOBILE_WAREHOUSE_DOCUMENT);
}

const CREATE_INVENTORY_DOCUMENT = gql`
  mutation CreateInventoryDocument($createInventoryDocumentInput: CreateInventoryDocumentInput!) {
    createInventoryDocument(createInventoryDocumentInput: $createInventoryDocumentInput) {
      inId,
      inOut
    }
  }
`;

export function useCreateInventoryDocument() {
  return useMutation<
    { createInventoryDocument: { inId: string | null, inOut: string | null } },
    { createInventoryDocumentInput: IInventoryDocumentInput }
  >(CREATE_INVENTORY_DOCUMENT);
}
