import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { IBasicState } from "store/redux/types";


const initialState: IBasicState = {
  status: "offline",
  userData: undefined,
  loadTimestamp: undefined,
};

export const basicSlice = createSlice({
  name: "basic",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<IBasicState["userData"]>) => {
      state.userData = action.payload;
      state.loadTimestamp = new Date().getTime();
    },
    setOnline: (state) => {
      state.status = "online";
    },
    setOffline: (state) => {
      state.status = "offline";
    },
  },
});

export const { setUserData, setOnline, setOffline } = basicSlice.actions;

export const userData = (state: RootState) => state.basic.userData;
export const loadTimestamp = (state: RootState) => state.basic.loadTimestamp;
export const status = (state: RootState) => state.basic.status;

export default basicSlice.reducer;
