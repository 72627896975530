import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { ICodelistsState } from "store/redux/types";


const initialState: ICodelistsState = {
  currencies: [],
  units: [],
  warehouses: [],
  companyInfo: {
    name: "",
    address: "",
    city: "",
    postalCode: "",
    country: "",
    ico: "",
    dic: "",
  },
  loadTimestamp: undefined,
};

export const codelistsSlice = createSlice({
  name: "codelists",
  initialState,
  reducers: {
    setCodelists: (state, action: PayloadAction<ICodelistsState>) => {
      state.currencies = action.payload.currencies;
      state.units = action.payload.units;
      state.warehouses = action.payload.warehouses;
      state.companyInfo = action.payload.companyInfo;
      state.loadTimestamp = new Date().getTime();
    },
  },
});

export const { setCodelists } = codelistsSlice.actions;

export const currencies = (state: RootState) => state.codelists.currencies;
export const units = (state: RootState) => state.codelists.units;
export const warehouses = (state: RootState) => state.codelists.warehouses;
export const companyInfo = (state: RootState) => state.codelists.companyInfo;
export const articlesByWarehouse = (state: RootState, id: number) => state.codelists.warehouses.find((warehouse) => warehouse.id === id)?.articles;

export default codelistsSlice.reducer;
