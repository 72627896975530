import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { IDocumentsState } from "store/redux/types";


const initialState: IDocumentsState = {
  documents: [],
};

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    addDocument: (state, action) => {
      state.documents.push(action.payload);
    },
    updateDocument: (state, action) => {
      state.documents[action.payload.index] = action.payload.document;
    },
    removeDocument: (state, action) => {
      state.documents.splice(action.payload, 1);
    },
  },
});

export const { addDocument, updateDocument, removeDocument } = documentsSlice.actions;

export const documents = (state: RootState) => state.documents.documents;

export default documentsSlice.reducer;
