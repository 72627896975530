import { useState } from "react";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import { Box, Divider, Stack } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import SnackbarCloseButton from "components/common/SnackbarCloseButton";
import { useAppContext } from "context/AppContext";
import { useModalContext } from "context/ModalContext";
import { useCreateMobileWarehouseDocument } from "hooks/useAPI";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";
import { articles } from "store/redux/articles";
import { setOffline } from "store/redux/basic";
import { buyers, suppliers } from "store/redux/customers";
import { removeDocument } from "store/redux/documents";
import { IDocument } from "types";
import { WDocumentType } from "types/enums";
import { formatDate } from "utils";

import EditDocumentModal from "../EditDocumentModal";
import EditTransferModal from "../EditTransferModal";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ArrowLeftAltIcon = styled(ArrowRightAltIcon)({ transform: "rotate(180deg)" });

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Item = ({ label, value }: { label: string; value: string }) => (
  <Stack direction="row" spacing={0.5}>
    <Typography variant="body2" color="text.secondary">
      {label}
    </Typography>
    <Typography variant="body2" color="text.secondary" fontWeight={600}>
      {value}
    </Typography>
  </Stack>
);

interface IProps extends IDocument {
  index: number;
}

export default function DocumentItem({
  type,
  createdAt,
  customerId,
  warehouseId,
  sourceWarehouseId,
  note,
  items,
  currencyId,
  price,
  userId,
  index,
  comment,
}: IProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const { showModal, closeModal } = useModalContext();
  const { reaload } = useAppContext();
  const [expanded, setExpanded] = useState(false);

  const { status } = useAppSelector((store) => store.basic);
  const suppliersData = useAppSelector(suppliers);
  const buyersData = useAppSelector(buyers);
  const customers = [...suppliersData, ...buyersData];
  const { warehouses, currencies } = useAppSelector((state) => state.codelists);

  const customer = customers.find((c) => c.id === customerId);
  const warehouse = warehouses.find((w) => w.id === warehouseId);
  const sourceWarehouse = warehouses.find((w) => w.id === sourceWarehouseId);
  const currency = currencies.find((c) => c.id === currencyId);
  const articlesData = useAppSelector(articles);

  const [createDocument] = useCreateMobileWarehouseDocument();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getLogo = () => {
    switch (type) {
      case WDocumentType.IN:
        return <ArrowLeftAltIcon />;
      case WDocumentType.OUT:
        return <ArrowRightAltIcon />;
      case WDocumentType.TRANSFER:
        return <MoveDownIcon />;
    }
  };

  const getTitle = () => {
    switch (type) {
      case WDocumentType.IN:
        return "Skladová príjemka";
      case WDocumentType.OUT:
        return "Skladová výdajka";
      case WDocumentType.TRANSFER:
        return "Skaldový presun";
    }
  };

  const getContent = () => {
    if (type === WDocumentType.TRANSFER) {
      return (
        <>
          <Item label="Zo skladu:" value={sourceWarehouse?.name ?? ""} />
          <Item label="Do skladu:" value={warehouse?.name ?? ""} />
        </>
      );
    }

    if (type === WDocumentType.IN) {
      return (
        <>
          <Item label="Od dodávateľa:" value={customer?.name ?? ""} />
          <Item label="Do skladu:" value={warehouse?.name ?? ""} />
        </>
      );
    }

    if (type === WDocumentType.OUT) {
      return (
        <>
          <Item label="Zo skladu:" value={warehouse?.name ?? ""} />
          <Item label="Pre odberateľa:" value={customer?.name ?? ""} />
        </>
      );
    }
  };

  const handleDelete = () => {
    confirm({
      dialogProps: { fullWidth: true },
      title: "Naozaj chcete vymazať tento dokument?",
      description: (
        <Stack>
          <Item label="Typ:" value={getTitle()} />
          {getContent()}
        </Stack>
      ),
    })
      .then(async () => {
        try {
          dispatch(removeDocument(index));
          enqueueSnackbar("Dokument bol vymazaný", { variant: "success" });
        } catch (e) {
          enqueueSnackbar("Dokument sa nepodarilo vymazať", { variant: "error" });
        }
      })
      .catch(() => {
        /* ... */
      });
  };

  const getDataForSync = () => {
    if (type === WDocumentType.TRANSFER) {
      return {
        sourceWarehouseId,
        createdAt,
        note,
        currencyId,
        warehouseId,
        items,
        type,
        userId,
        price,
        comment,
      };
    }

    return {
      createdAt,
      note,
      currencyId,
      warehouseId,
      items,
      type,
      userId,
      customerId,
      price,
      comment,
    };
  };

  const handleSync = () => {
    confirm({
      dialogProps: { fullWidth: true },
      title: "Naozaj odoslať tento dokument?",
      description: "Dokument bude odoslaný na server a nebude možné ho už v aplikácii upravovať.",
    })
      .then(async () => {
        try {
          await createDocument({
            variables: {
              createMobileWarehouseDocumentInput: getDataForSync(),
            },
          });
          dispatch(removeDocument(index));
          reaload();
          enqueueSnackbar("Dokument bol úspešne odoslaný", { variant: "success" });
        } catch (e: any) {
          if (e.message.includes("Insufficient stock")) {
            const ids: string[] = e.message.split(": ")[2].split(", ");
            const articles = ids.map((id) => {
              const article = articlesData?.find((article) => article.id === parseInt(id));
              return `[${article?.id}] ${article?.name}`;
            });

            enqueueSnackbar(
              <Box>
                <Typography variant="body2" fontWeight={600}>
                  Na sklade nie je dostatok tovaru:
                </Typography>
                <Stack pt={1}>
                  {articles.map((article) => (
                    <Typography key={article} variant="body2">
                      {article}
                    </Typography>
                  ))}
                </Stack>
              </Box>,
              {
                variant: "error",
                action: SnackbarCloseButton,
                persist: true,
              }
            );
          } else if (e.message.includes("Failed to fetch")) {
            enqueueSnackbar("Zdá sa, že nemáte internetové pripojenie", { variant: "warning" });
            dispatch(setOffline());
          } else {
            enqueueSnackbar(`Dokument sa nepodarilo odoslať. Dôvod:\u00a0${e.message}`, { variant: "error" });
          }
        }
      })
      .catch(() => {
        /* ... */
      });
  };

  const handleEditDocument = () => {
    showModal(
      <EditDocumentModal
        handleClose={closeModal}
        index={index}
        data={{
          type,
          createdAt,
          customerId,
          warehouseId,
          note,
          items,
          currencyId,
          price,
          userId,
        }}
      />
    );
  };

  const handleEditTransfer = () => {
    showModal(
      <EditTransferModal
        handleClose={closeModal}
        index={index}
        data={{
          type,
          createdAt,
          warehouseId,
          sourceWarehouseId,
          note,
          items,
          currencyId,
          price,
          userId,
        }}
      />
    );
  };

  const handleEdit = () => {
    if (type === WDocumentType.TRANSFER) {
      handleEditTransfer();
    } else {
      handleEditDocument();
    }
  };

  return (
    <Card
      sx={{
        bgcolor: (theme) => (theme.palette.mode === "light" ? theme.palette.common.white : theme.palette.grey[900]),
      }}
    >
      <CardHeader
        avatar={<Avatar sx={{ bgcolor: theme.palette.primary.main }}>{getLogo()}</Avatar>}
        action={
          <IconButton color="info" disabled={status === "offline"} onClick={handleSync}>
            <CloudUploadIcon />
          </IconButton>
        }
        title={getTitle()}
        subheader={formatDate(createdAt, true)}
      />
      <CardContent>
        {getContent()}
        <Item label="Celkom k úhrade:" value={`${price}${currency?.description ?? currency?.name ?? ""}`} />
        <Item label="Poznámka:" value={note} />
        {comment && <Item label="Komentár:" value={comment} />}
      </CardContent>
      <CardActions disableSpacing>
        <IconButton onClick={handleEdit}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
        <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography sx={{ pb: 1 }} variant="body2" fontWeight={600} color="text.secondary">
            Položky:
          </Typography>
          <Stack spacing={1}>
            {items.map((item, index) => {
              const article = articlesData?.find((article) => article.id === item.articleId);

              return (
                <Box key={`${index}-${item.articleId}`}>
                  <Stack direction="column">
                    <Item label="Názov:" value={article?.name ?? ""} />
                    <Item label="Množstvo:" value={`${(item.quantity ?? 0)} ${article?.unit.name}`} />
                    <Item
                      label="Cena za kus:"
                      value={`${(item.price ?? 0)}${currency?.description ?? currency?.name ?? ""}`}
                    />
                    <Item
                      label="Cena:"
                      value={`${(item.price ?? 0) * (item.quantity ?? 0)}${currency?.description ?? currency?.name ?? ""}`}
                    />
                  </Stack>
                  {index !== items.length - 1 && <Divider />}
                </Box>
              );
            })}
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  );
}
