import CachedIcon from "@mui/icons-material/Cached";
import { styled } from "@mui/material/styles";

interface IProps {
  loading: string;
  color?: string;
}

const AnimatedCachedIcon = styled(CachedIcon)<IProps>(({ loading, color = "white" }) => ({
  "@keyframes spin": {
    "0%": {
      transform: "rotate(360deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },
  animation: loading === "true" ? "spin 1s infinite ease" : undefined,
  color: color,
}));

export default AnimatedCachedIcon