import { useEffect, useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import {
  BottomNavigationAction,
  Box,
  Drawer,
  ListItemIcon,
  ListItemText,
  BottomNavigation as MUIBottomNavigation,
  MenuItem,
  MenuList,
  Paper,
  styled,
} from "@mui/material";
import AddDocumentModal from "components/modules/AddDocumentModal";
import AddTransferModal from "components/modules/AddTransferModal";
import { useModalContext } from "context/ModalContext";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { WDocumentType } from "types/enums";

const ArrowLeftAltIcon = styled(ArrowRightAltIcon)({ transform: "rotate(180deg)" });

export default function BottomNavigation() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { showModal, closeModal } = useModalContext();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleClose = () => {
    if(pathname !== "/") navigate("/")
    closeModal();
  }

  const handleAdd = (type: WDocumentType.IN | WDocumentType.OUT) => () => {
    showModal(<AddDocumentModal handleClose={handleClose} type={type} />);
    toggleDrawer(false);
  };

  const handleTransfer = () => () => {
    showModal(<AddTransferModal handleClose={handleClose} />);
    toggleDrawer(false);
  };

  return (
    <>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        elevation={3}>
        <MUIBottomNavigation showLabels value={open ? "add" : pathname} sx={{ height: "72px", pb: 2 }}>
          <BottomNavigationAction component={Link} to="/" value="/" label="Dokumenty" icon={<SwapHorizIcon />} />
          <BottomNavigationAction
            value={"add"}
            label="Pridať"
            icon={<AddCircleIcon />}
            onClick={() => toggleDrawer(!open)}
          />
          <BottomNavigationAction
            component={Link}
            to="/price-list"
            value="/price-list"
            label="Cenník"
            icon={<ListAltIcon />}
          />
        </MUIBottomNavigation>
      </Paper>
      <Drawer anchor="bottom" open={open} onClose={() => toggleDrawer(false)}>
        <Box pb={10}>
          <MenuList>
            <MenuItem onClick={handleAdd(WDocumentType.IN)}>
              <ListItemIcon>
                <ArrowLeftAltIcon />
              </ListItemIcon>
              <ListItemText>Príjemka</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleAdd(WDocumentType.OUT)}>
              <ListItemIcon>
                <ArrowRightAltIcon />
              </ListItemIcon>
              <ListItemText>Výdajka</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleTransfer()}>
              <ListItemIcon>
                <MoveDownIcon />
              </ListItemIcon>
              <ListItemText>Presun</ListItemText>
            </MenuItem>
          </MenuList>
        </Box>
      </Drawer>
    </>
  );
}
