import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { SnackbarKey, closeSnackbar } from "notistack";

const SnackbarCloseButton = (snackbarId: SnackbarKey) => {
  return (
    <IconButton sx={{ color: "white" }} onClick={() => closeSnackbar(snackbarId)}>
      <CloseIcon />
    </IconButton>
  );
};

export default SnackbarCloseButton;
