import React, { useEffect } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { Alert, Autocomplete, Divider, IconButton, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppSelector } from "hooks/useRedux";
import { IArticle, IDocumentItems } from "types";

interface IProps {
  data: IDocumentItems;
  index: number;
  onChange: (index: number, data: IDocumentItems) => void;
  onDelete: (index: number) => void;
  warehouseId?: number;
  selectedItems?: number[];
  isLast: boolean;
  articles: IArticle[];
}

interface IOption {
  label: string;
  id: number;
}

const ItemInput: React.FC<IProps> = ({ data, index, onChange, onDelete, warehouseId, selectedItems, isLast, articles }) => {
  const { userData } = useAppSelector((store) => store.basic);
  const article = articles?.find((article) => article.id === data.articleId);

  useEffect(() => {
    if (article && data.price === 0) {
      onChange(index, { ...data, price: article.priceVat });
    }
  }, [article]);

  const handleNameChange = (_event: any, newValue: IOption | null) => {
    const newArticle = articles?.find((article) => article.id === newValue?.id);

    if (newArticle) onChange(index, { ...data, articleId: newArticle.id, price: newArticle.priceVat, quantity: 1 });
    if (newValue === null) onChange(index, { ...data, articleId: -1, price: 0, quantity: 1 });
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(index, { ...data, quantity: e.target.value ? Number(e.target.value) : null });
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(index, { ...data, price: e.target.value ? Number(e.target.value) : null });
  };

  const getTotalPrice = () => {
    const totalPrice = (data.quantity ?? 0) * (data.price ?? 0);
    const value = totalPrice.toFixed(2).replace(".", ",");

    return value;
  };

  const getQuantityMax = () => {
    if (warehouseId) {
      if (article) return article.quantity;
      else return 1;
    }

    if (article) return undefined;
    else return 1;
  };

  const getOption = (option: IArticle) => {
    return {
      label: `[${option.id}] ${option.name} - ${option.category.name}/${option.subcategory.name}`,
      id: option.id,
    };
  };

  const getOptions = () => {
    if (warehouseId) {
      return (
        articles
          ?.filter((option) => !selectedItems?.some((el) => el === option.id) && option.quantity > 0)
          .map((option) => getOption(option)) || []
      );
    }

    return (
      articles
        ?.filter((option) => !selectedItems?.some((el) => el === option.id))
        .map((option) => getOption(option)) || []
    );
  };

  if (!article && data.articleId !== -1) return <Alert sx={{mb: 1}} severity="warning">{`Položka ${data.articleId} nebola nájdená!`}</Alert>;

  return (
    <Grid container spacing={1}>
      <Grid xs={12}>
        <Autocomplete
          value={article ? getOption(article) : null}
          onChange={handleNameChange}
          options={getOptions() || []}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Názov"
              helperText={warehouseId && article?.quantity ? `Na sklade: ${article?.quantity}` : ""}
            />
          )}
          blurOnSelect="touch"
        />
      </Grid>
      <Grid xs={4}>
        <TextField
          required
          fullWidth
          label={"Množstvo"}
          type="number"
          value={data.quantity ?? ""}
          onChange={handleQuantityChange}
          inputProps={{ max: getQuantityMax(), min: 1, step: 1, inputMode: "numeric" }}
        />
      </Grid>
      <Grid xs={2}>
        <TextField fullWidth label="MJ" value={article?.unit.name ?? ""} disabled />
      </Grid>
      <Grid xs={6}>
        <TextField
          fullWidth
          required
          label={"JC"}
          type="number"
          value={data.price ?? ""}
          onChange={handlePriceChange}
          inputProps={{ inputMode: "decimal" }}
          disabled={userData?.role !== "admin"}
        />
      </Grid>
      <Grid xs={10}>
        <TextField fullWidth label="Spolu" value={getTotalPrice()} disabled />
      </Grid>
      <Grid xs={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <IconButton onClick={() => onDelete(index)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
      {!isLast && (
        <Grid xs={12}>
          <Divider />
        </Grid>
      )}
    </Grid>
  );
};

export default ItemInput;
