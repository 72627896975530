import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { ICustomersState } from "store/redux/types";
import { CustomerType } from "types/enums";

const initialState: ICustomersState = {
  suppliers: [],
  buyers: [],
  loadTimestamp: undefined,
};

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setCustomers: (state, action: PayloadAction<ICustomersState>) => {
      state.buyers = action.payload.buyers;
      state.suppliers = action.payload.suppliers;
      state.loadTimestamp = new Date().getTime();
    },
  },
});

export const { setCustomers } = customersSlice.actions;

export const suppliers = (state: RootState) => state.customers.suppliers;
export const buyers = (state: RootState) => state.customers.buyers;
export const customerByType = (state: RootState, type: CustomerType) => type === CustomerType.BUYER ? buyers(state) : suppliers(state);

export default customersSlice.reducer;
