import React from "react";

import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import { useAppSelector } from "hooks/useRedux";
import { customerByType } from "store/redux/customers";
import { ICustomer } from "types";
import { CustomerType } from "types/enums";

import CompanyItem from "../CompanyItem";

interface IProps {
  customerId?: number;
  onChangeCustomerId: (id?: number) => void;
  type: CustomerType;
  showAddress?: boolean;
  label?: string;
}

const CustomerSection: React.FC<IProps> = ({ onChangeCustomerId, customerId, type, showAddress = true, label }) => {
  const data = useAppSelector((state) => customerByType(state, type));
  const selectedCustomer = data.find((customer) => customer.id === customerId);

  const handleChangeCustomer = (_event: any, newValue: { label: string; id: number } | null) => {
    if (newValue) onChangeCustomerId(newValue.id);
    else onChangeCustomerId(undefined);
  };

  const getCustomerOptionLabel = (option: ICustomer) =>
    `[${option.id}] ${option.name}${option.note ? ` - ${option.note}` : ""}`;

  return (
    <Stack>
      <Autocomplete
        value={
          selectedCustomer && customerId ? { label: getCustomerOptionLabel(selectedCustomer), id: customerId } : null
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={data.map((option) => ({ label: getCustomerOptionLabel(option), id: option.id })) || []}
        getOptionLabel={(option) => option.label}
        onChange={handleChangeCustomer}
        renderInput={(params) => <TextField label={label} {...params} />}
        blurOnSelect="touch"
      />
      <Box>
        {selectedCustomer && showAddress && (
          <CompanyItem
            {...selectedCustomer}
            stackProps={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          />
        )}
      </Box>
    </Stack>
  );
};

export default CustomerSection;
