import React from "react";

import { Box, Stack, Typography } from "@mui/material";
import DocumentItem from "components/modules/DocumentItem";
import { useAppSelector } from "hooks/useRedux";
import { documents } from "store/redux/documents";

const Documents: React.FC = () => {
  const data = useAppSelector(documents);

  return (
    <Box pt={1} pb={2}>
      {data.length === 0 && (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
          height={"calc(100vh - 130px)"}>
          <Typography variant="body1" textAlign={"center"}>
            Nemáš žiadne neodoslané dokumenty
          </Typography>
        </Box>
      )}
      <Stack spacing={1}>
        {data.map((item, index) => (
          <DocumentItem key={index} index={index} {...item} />
        ))}
      </Stack>
    </Box>
  );
};

export default Documents;
