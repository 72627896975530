import Default from "components/layout/Default";
import Main from "components/layout/Main";
import Documents from "components/pages/Documents";
import Inventory from "components/pages/Inventory";
import PriceList from "components/pages/PriceList";
import SingIn from "components/pages/SingIn";
import { useRoutes } from "react-router-dom";

const AppRoutes = () => {
  const routes = [
    {
      path: "/login",
      element: <Default />,
      children: [
        {
          path: "/login",
          element: <SingIn />,
        },
      ],
    },
    {
      path: "/",
      element: <Main />,
      children: [
        {
          path: "/",
          element: <Documents />,
        },
        {
          path: "/price-list",
          element: <PriceList />,
        },
        {
          path: "/inventory",
          element: <Inventory />,
        },
      ],
    },
  ];

  return useRoutes(routes);
};

export default AppRoutes;
