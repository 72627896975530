import { useState } from "react";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import MenuIcon from "@mui/icons-material/Menu";
import {
  useTheme,
  AppBar as MUIAppBar,
  Toolbar,
  IconButton,
  Box,
  Divider,
  Drawer,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import AnimatedCachedIcon from "components/common/AnimatedCachedIcon";
import ToggleColorMode from "components/common/ToggleColorMode";
import { useColorModeContext } from "context/ColorModeContext";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";
import { initInventory } from "store/redux/inventory";
import { formatDate } from "utils";

interface IProps {
  reaload: () => Promise<void>;
}

export default function AppBar({ reaload }: IProps) {
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const theme = useTheme();
  const navigate = useNavigate();
  const { toggleColorMode } = useColorModeContext();
  const { status, loadTimestamp, userData } = useAppSelector((store) => store.basic);
  const { isStarted } = useAppSelector((store) => store.inventory);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const logOut = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const handleReload = async () => {
    setLoading(true);
    await reaload();
    setLoading(false);
  };

  const handleInventory = async () => {
    await handleReload();
    toggleDrawer(false)();

    if (isStarted) {
      confirm({
        dialogProps: { fullWidth: true },
        title: "Rozpracovaná inventúra!",
        description: "Máte rozpracovanú inventúru. Chcete pokračovať alebo začať novú?",
        confirmationText: "Pokračovať",
        cancellationText: "Začať novú",
        confirmationButtonProps: { color: "primary" },
      })
        .then(async () => navigate("/inventory"))
        .catch(async () => {
          await dispatch(initInventory());
          navigate("/inventory");
        });
    } else {
      await dispatch(initInventory());
      navigate("/inventory");
    }
  };

  return (
    <MUIAppBar position="fixed">
      <Toolbar variant="regular">
        <Box width={"100%"}>
          <Stack justifyContent={"space-between"} alignItems={"center"} direction={"row"}>
            <Typography variant="h6" noWrap component="div" flex={1}>
              Sklad
            </Typography>
            <IconButton sx={{ color: "white" }} onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          </Stack>
          <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
            <Box
              sx={{
                p: 2,
                backgroundColor: "background.default",
              }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <ToggleColorMode mode={theme.palette.mode} toggleColorMode={toggleColorMode} />
                <IconButton onClick={toggleDrawer(false)}>
                  <CloseRoundedIcon />
                </IconButton>
              </Box>
              <Divider sx={{ my: 3 }} />
              <Box p={2} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography variant="body2">{"Status"}</Typography>
                <Stack direction={"row"} alignItems={"center"}>
                  <FiberManualRecordIcon fontSize="inherit" color={status === "offline" ? "error" : "success"} />
                  <Typography
                    variant="body1"
                    color={status === "offline" ? theme.palette.error.main : theme.palette.success.main}>
                    {status}
                  </Typography>
                </Stack>
              </Box>
              <Box p={2} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography variant="body2">{"Posledná aktualizácia"}</Typography>
                <Typography variant="body1">
                  {loadTimestamp ? formatDate(new Date(loadTimestamp).toISOString(), true) : "-"}
                </Typography>
              </Box>
              <Box p={2} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography variant="body2">{"Prihlásený ako"}</Typography>
                <Typography variant="body1">{userData?.username || "-"}</Typography>
              </Box>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={handleReload}
                disabled={loading}
                endIcon={<AnimatedCachedIcon loading={`${loading}`} color="inherit" />}>
                Znova načítať dáta
              </Button>
              {userData?.role === "admin" && (
                <>
                  <Divider sx={{ my: 2 }} />
                  <Button
                    variant="contained"
                    color="warning"
                    fullWidth
                    disabled={status === "offline"}
                    onClick={handleInventory}>
                    Inventúra
                  </Button>
                </>
              )}
              <Divider sx={{ my: 2 }} />
              <Button color="error" variant="outlined" fullWidth onClick={logOut}>
                Odhlásiť sa
              </Button>
            </Box>
          </Drawer>
        </Box>
      </Toolbar>
    </MUIAppBar>
  );
}
