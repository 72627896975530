import { createSlice } from "@reduxjs/toolkit";
import { IInventoryState } from "store/redux/types";
import { IArticle } from "types";

const initialState: IInventoryState = {
  warehouseId: undefined,
  activeStep: 0,
  articles: [],
  isStarted: false,
  currencyId: 1,
  inventoryFrom: 1,
  inventoryTo: 18,
};

export const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    initInventory: () => initialState,
    startInventory: (state, action) => {
      const articles = action.payload;

      state.activeStep = 1;
      state.isStarted = true;
      state.articles = articles.map((article: IArticle) => ({
        id: article.id,
        name: article.name,
        description: `Kategória: ${article.category.name}, Výrobca: ${article.manufacturer ?? "---"}, Poznámka: ${article.note ?? "---"}`,
        oldQuantity: article.quantity,
        newQuantity: article.quantity,
      }));
    },
    setWarehouseId: (state, action) => {
      state.warehouseId = action.payload;
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    updateArticleQuantity: (state, action) => {
      const { articleId, quantity } = action.payload;
      const article = state.articles.find((article) => article.id === articleId);

      if (article) {
        article.newQuantity = quantity;
      }
    },
    setCurrencyId: (state, action) => {
      state.currencyId = action.payload;
    },
    setInventoryFrom: (state, action) => {
      state.inventoryFrom = action.payload;
    },
    setInventoryTo: (state, action) => {
      state.inventoryTo = action.payload;
    },
  },
});

export const {
  startInventory,
  setWarehouseId,
  setActiveStep,
  updateArticleQuantity,
  initInventory,
  setCurrencyId,
  setInventoryFrom,
  setInventoryTo,
} = inventorySlice.actions;

export default inventorySlice.reducer;
