import { ApolloProvider } from "@apollo/client";
import { Box, CircularProgress, CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import client from "config/ApolloClient";
import MUITheme from "config/MUITheme";
import { ModalProvider } from "context/ModalContext";
import { sk } from "date-fns/locale";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import AppRoutes from "routes";
import { store } from "store";

const basename = process.env.PUBLIC_URL || '';

function App() {
  const persistor = persistStore(store);

  return (
    <MUITheme>
      <Provider store={store}>
        <PersistGate
          loading={
            <Box display={"flex"} justifyContent={"center"} height={"100vh"} alignItems={"center"}>
              <CircularProgress />
            </Box>
          }
          persistor={persistor}>
          <BrowserRouter basename={basename}>
            <ApolloProvider client={client}>
              <SnackbarProvider
                maxSnack={3}
                preventDuplicate
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}>
                <CssBaseline />
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sk}>
                  <ModalProvider>
                    <AppRoutes />
                  </ModalProvider>
                </LocalizationProvider>
              </SnackbarProvider>
            </ApolloProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </MUITheme>
  );
}

export default App;
