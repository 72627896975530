import { format } from "date-fns";

export const formatPostalCode = (postalCode: string) => {
  return postalCode.replace(/(\d{3})(\d{2})/, "$1 $2");
};

export const formatDate = (date: string, withTime = false) => {
  const dateObj = new Date(date);
  const formattedDate = format(dateObj, "dd.MM.yyyy");
  const formattedTime = format(dateObj, "HH:mm");
  return withTime ? `${formattedDate}, ${formattedTime}` : formattedDate;
};
