import { useEffect, useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  IconButton,
  MenuItem,
  Stack,
  Select as MUISelect,
  Typography,
  lighten,
  styled,
  darken,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import { DatePicker } from "@mui/x-date-pickers";
import BackdropLoading from "components/common/BackdropLoading";
import Modal from "components/common/Modal";
import Select from "components/common/Select";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { enqueueSnackbar } from "notistack";
import { userData } from "store/redux/basic";
import { articlesByWarehouse } from "store/redux/codelists";
import { updateDocument } from "store/redux/documents";
import { IArticle, ICurrency, IDocument, IDocumentItems } from "types";
import { WDocumentType } from "types/enums";

import ItemInput from "../EditDocumentModal/ItemInput";

interface IProps {
  handleClose: () => void;
  data: IDocument;
  index: number;
}

const SectionBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor:
    theme.palette.mode === "light" ? lighten(theme.palette.primary.main, 0.8) : darken(theme.palette.primary.main, 0.8),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignContent: "center",
}));

const getCurrencyName = (data: ICurrency[], currencyId?: number) => {
  if (!currencyId) return undefined;

  const currency = data.find((currency) => currency.id === currencyId);
  return currency?.description ?? currency?.name ?? undefined;
};

const EditTransferModal = ({ handleClose, data, index }: IProps) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(userData);
  const { currencies, warehouses } = useAppSelector((state) => state.codelists);

  const [loading, setLoading] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [articles, setArticles] = useState<IArticle[]>([]);

  const [createdAt, setCreatedAt] = useState<Date | null>(new Date(data.createdAt));
  const [note, setNote] = useState<string>(data.note ?? "");
  const [currencyId, setCurrencyId] = useState<number | undefined>(data.currencyId);
  const [items, setItems] = useState<IDocumentItems[]>(data.items);
  const [from, setFrom] = useState<number | undefined>(data.sourceWarehouseId ?? undefined);
  const [to, setTo] = useState<number | undefined>(data.warehouseId);
  const [comment, setComment] = useState<string>(data.comment ?? "");

  const currency = getCurrencyName(currencies, currencyId);
  const articlesByWarehouseData = useAppSelector((store) => articlesByWarehouse(store, from ?? -1));

  const fetchArticles = async () => {
    if (articlesByWarehouseData && articlesByWarehouseData.length) {
      // if (from === data.sourceWarehouseId) {
      //   const newArticles = articlesByWarehouseData.map((article) => {
      //     const item = data.items.find((i) => i.articleId === article.id);
      //     if (item) return { ...article, quantity: article.quantity + (item.quantity ?? 0) };
      //     return article;
      //   });
      //   setArticles(newArticles);
      // } else setArticles(articlesByWarehouseData);
      setArticles(articlesByWarehouseData);
    } else setArticles([]);
  };

  useEffect(() => {
    if (from && articlesByWarehouseData && articlesByWarehouseData.length) fetchArticles();
  }, [from, articlesByWarehouseData]);

  useEffect(() => {
    if (to && items.length) setIsInit(true);
  }, [to, items]);

  useEffect(() => {
    if (currencies && currencies.length > 0 && !currencyId) {
      setCurrencyId(currencies[0].id);
    }
  }, [currencies]);

  useEffect(() => {
    if (from && isInit) {
      setItems([]);
      setTo(undefined);
    }
  }, [from]);

  const handleUpdate = async () => {
    if (!createdAt || !currencyId || !from || !to || !items.length || !user?.id) return;
    setLoading(true);
    const price = items.reduce((acc, item) => acc + (item.price ?? 0) * (item.quantity ?? 0), 0);
    try {
      dispatch(
        updateDocument({
          index,
          document: {
            createdAt: createdAt.toISOString(),
            note,
            currencyId,
            sourceWarehouseId: from,
            warehouseId: to,
            items,
            userId: user?.id,
            price,
            type: WDocumentType.TRANSFER,
            comment,
          },
        })
      );
      handleClose();
      enqueueSnackbar(`Skladový presun bol upravený`, { variant: "success" });
    } catch (e: any) {
      enqueueSnackbar(`Skladový presun sa nepodarilo upraviť`, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const getTotalPrice = () => {
    const totalPrice = items.reduce((acc, item) => acc + (item.price ?? 0) * (item.quantity ?? 0), 0);
    const value = totalPrice
      .toFixed(2)
      .replace(".", ",")
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    if (currency) return `${value} ${currency}`;
    return value;
  };

  const isPossibleToUpdate = () =>
    createdAt
    && currencyId
    && from
    && items.length > 0
    && items.every((item) => item.articleId !== -1 && (item.price ?? 0) > 0 && (item.quantity ?? 0) > 0)
    && to;

  const handleAddItem = () => setItems((prev) => [...prev, { price: 0, quantity: 1, articleId: -1 }]);
  const handleRemoveItem = (index: number) => setItems((prev) => prev.filter((_, i) => i !== index));
  const handleItemChange = (index: number, data: IDocumentItems) => {
    setItems((prev) => {
      const newItems = [...prev];
      newItems[index] = data;
      return newItems;
    });
  };

  return (
    <Modal
      title="Upraviť skladový presun"
      onConfirm={handleUpdate}
      disableConfirm={!isPossibleToUpdate()}
      confirmText="Uložiť"
      onClose={handleClose}>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Select
            label="Zo skladu *"
            fullWidth
            value={from ?? ""}
            onChange={(e) => setFrom(e.target.value as number)}
            disabled={warehouses.length === 0}>
            {warehouses.map((warehouse) => (
              <MenuItem key={warehouse.id} value={warehouse.id}>
                {warehouse.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid xs={12}>
          <DatePicker
            label="Vystavená dňa *"
            sx={{ width: "100%" }}
            value={createdAt}
            onChange={(newValue) => setCreatedAt(newValue)}
            timezone="Europe/Bratislava"
          />
        </Grid>
        <Grid xs={12}>
          <Stack rowGap={0.5}>
            <SectionBox width={"100%"} height={"100%"}>
              <Typography variant="subtitle1">Mena</Typography>
            </SectionBox>
            <MUISelect
              fullWidth
              value={currencyId ?? ""}
              disabled={currencies.length === 0}
              onChange={(e) => setCurrencyId(e.target.value as number)}>
              {currencies.map((currency) => (
                <MenuItem key={currency.id} value={currency.id}>
                  {currency.name}
                </MenuItem>
              ))}
            </MUISelect>
          </Stack>
        </Grid>
        <Grid xs={12}>
          <Stack rowGap={0.5}>
            <SectionBox width={"100%"} height={"100%"}>
              <Typography variant="subtitle1">Na sklad *</Typography>
            </SectionBox>
            <Select
              label=""
              fullWidth
              value={to ?? ""}
              onChange={(e) => setTo(e.target.value as number)}
              disabled={warehouses.length === 0}>
              {warehouses.map((warehouse) => (
                <MenuItem key={warehouse.id} disabled={warehouse.id === from} value={warehouse.id}>
                  {warehouse.name}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Grid xs={12}>
          <TextField label="Poznámka" fullWidth value={note} onChange={(e) => setNote(e.target.value)} />
        </Grid>
        <Grid xs={12}>
          <Stack rowGap={1}>
            <SectionBox>
              <Typography variant="subtitle1" fontWeight={400}>
                Položky
              </Typography>
              <IconButton onClick={handleAddItem}>
                <AddCircleIcon />
              </IconButton>
            </SectionBox>
            <Stack rowGap={1}>
              {items.map((item, index) => (
                <ItemInput
                  key={`item-${index}`}
                  articles={articles}
                  data={item}
                  index={index}
                  onChange={handleItemChange}
                  onDelete={handleRemoveItem}
                  warehouseId={from ?? -1}
                  selectedItems={items.map((item) => item.articleId ?? -1)}
                  isLast={index === items.length - 1}
                />
              ))}
            </Stack>
          </Stack>
        </Grid>
        <Grid xs={12}>
          <SectionBox width={{ xs: "100%", sm: "50%" }}>
            <Typography variant="subtitle1">Celkom k úhrade</Typography>
            <Typography variant="subtitle1">{getTotalPrice()}</Typography>
          </SectionBox>
        </Grid>
        <Grid xs={12}>
          <TextField label="Komentár" fullWidth value={comment} onChange={(e) => setComment(e.target.value)} />
        </Grid>
        <Grid xs={12}>
          <Typography variant="subtitle1" fontWeight={700}>
            Vystavil:
          </Typography>
          <Typography variant="subtitle1" color={"text.secondary"}>{`${user?.name} ${user?.surname}`}</Typography>
          <Typography variant="subtitle1" color={"text.secondary"}>
            {user?.email}
          </Typography>
        </Grid>
      </Grid>
      <BackdropLoading loading={loading} />
    </Modal>
  );
};

export default EditTransferModal;
