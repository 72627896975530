import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import articlesReducer from "store/redux/articles";
import basicReducer from "store/redux/basic";
import codelistsReducer from "store/redux/codelists";
import customersReducer from "store/redux/customers";
import documentsReducer from "store/redux/documents";
import inventoryReducer from "store/redux/inventory";

const reducers = combineReducers({
  basic: basicReducer,
  articles: articlesReducer,
  codelists: codelistsReducer,
  customers: customersReducer,
  documents: documentsReducer,
  inventory: inventoryReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
