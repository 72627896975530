import React from "react";

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Divider, List, ListItem, ListItemText, MenuItem, Stack, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Select from "components/common/Select";
import SnackbarCloseButton from "components/common/SnackbarCloseButton";
import CustomerSection from "components/modules/AddDocumentModal/CustomerSection";
import { useCreateInventoryDocument } from "hooks/useAPI";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { setOffline, userData } from "store/redux/basic";
import { articlesByWarehouse } from "store/redux/codelists";
import {
  initInventory,
  setActiveStep,
  setCurrencyId,
  setWarehouseId,
  startInventory,
  updateArticleQuantity,
  setInventoryTo,
  setInventoryFrom,
} from "store/redux/inventory";
import { IInventoryItem } from "types";
import { CustomerType } from "types/enums";

const numberOfHardcodedSteps = 2;

const Inventory: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { activeStep, warehouseId, articles, currencyId, inventoryFrom, inventoryTo } = useAppSelector(
    (store) => store.inventory
  );

  const user = useAppSelector(userData);
  const { warehouses, currencies } = useAppSelector((state) => state.codelists);
  const articlesByWarehouseData = useAppSelector((store) => articlesByWarehouse(store, warehouseId ?? -1));

  const currentArticle = articles?.[activeStep - 1];
  const isFirstStep = activeStep === 0;
  const maxSteps = (articles?.length ?? 0) + numberOfHardcodedSteps;
  const isResultStep = activeStep === articles.length + 1;

  const [createInventory] = useCreateInventoryDocument();

  const handleSubmit = async () => {
    if (!warehouseId || !user?.id) return;

    try {
      const items = articles.map((article) => ({
        id: article.id,
        oldQuantity: Number(article.oldQuantity),
        newQuantity: Number(article.newQuantity),
      }));
      await createInventory({
        variables: { createInventoryDocumentInput: { warehouseId, currencyId, inventoryFrom, inventoryTo, items, userId: user?.id } },
      });
      dispatch(initInventory());
      navigate("/");
      enqueueSnackbar("Inventúra bola úspešne uložená", { variant: "success" });
    } catch (e: any) {
      if (e.message.includes("Insufficient stock")) {
        const ids: string[] = e.message.split(": ")[2].split(", ");
        const badArticles = ids.map((id) => {
          const article = articlesByWarehouseData?.find((article) => article.id === parseInt(id));
          return `[${article?.id}] ${article?.name}`;
        });

        enqueueSnackbar(
          <Box>
            <Typography variant="body2" fontWeight={600}>
              Na sklade nie je dostatok tovaru:
            </Typography>
            <Stack pt={1}>
              {badArticles.map((article) => (
                <Typography key={article} variant="body2">
                  {article}
                </Typography>
              ))}
            </Stack>
          </Box>,
          {
            variant: "error",
            action: SnackbarCloseButton,
            persist: true,
          }
        );
      } else if (e.message.includes("Failed to fetch")) {
        enqueueSnackbar("Zdá sa, že nemáte internetové pripojenie", { variant: "warning" });
        dispatch(setOffline());
      } else {
        enqueueSnackbar(`Inventúru sa nepodarilo odoslať. Dôvod:\u00a0${e.message}`, { variant: "error" });
      }
    }
  };

  const handleNext = () => {
    if (isResultStep) {
      handleSubmit();
    } else if (activeStep === 0) {
      if (articlesByWarehouseData?.length) {
        dispatch(startInventory(articlesByWarehouseData));
      }
    } else dispatch(setActiveStep(activeStep + 1));
  };
  const handleBack = () => dispatch(setActiveStep(activeStep - 1));
  const handleWarehouseChange = (e: any) => dispatch(setWarehouseId(e.target.value as number));
  const handleCurrencyChange = (e: any) => dispatch(setCurrencyId(e.target.value as number));
  const handleInventoryFromChange = (id?: number) => dispatch(setInventoryFrom(id));
  const handleInventoryToChange = (id?: number) => dispatch(setInventoryTo(id));

  const getLabel = () => {
    if (warehouseId && activeStep !== 0) {
      return `Inventúra skladu: ${warehouses.find((warehouse) => warehouse.id === warehouseId)?.name}`;
    }

    return "Inventúra";
  };

  const getNextButtonLabel = () => {
    if (isFirstStep) return "Začať";
    if (isResultStep) return "Odoslať";

    return "Ďalej";
  };

  const disabledNextButton = () => {
    if (isFirstStep) return !warehouseId || !inventoryFrom || !inventoryTo || !currencyId;
    if (isResultStep) return false;

    return maxSteps - 1 === activeStep;
  };

  const handleQuantityChange = (e: any) =>
    dispatch(updateArticleQuantity({ articleId: currentArticle?.id, quantity: e.target.value }));

  const getArticleContent = () => {
    if (!currentArticle) return null;

    return (
      <Stack spacing={2}>
        <Stack>
          <Typography variant="body1" textAlign={"center"}>
            {currentArticle.name}
          </Typography>
          <Typography variant="caption" textAlign={"center"}>
            {currentArticle.description}
          </Typography>
        </Stack>
        <TextField fullWidth label={"Uložené množstvo"} type="number" value={currentArticle.oldQuantity} disabled />
        <TextField
          required
          fullWidth
          label={"Nové množstvo"}
          type="number"
          value={currentArticle.newQuantity ?? ""}
          onChange={handleQuantityChange}
          inputProps={{ min: 0, step: 1, inputMode: "numeric" }}
        />
      </Stack>
    );
  };

  const getColorForResult = (article: IInventoryItem) => {
    const diff = article.newQuantity - article.oldQuantity;
    if (diff === 0) return;

    return diff < 0 ? "error" : "green";
  }

  const getResult = () => {
    return (
      <List>
        {articles.map((article) => (
          <React.Fragment key={article.id}>
            <ListItem>
              <ListItemText
                primary={
                  <Stack>
                    <Typography fontWeight={600} variant="body1">
                      {`[${article.id}] ${article.name}`}
                    </Typography>
                    <Typography variant="caption">
                      {article.description}
                    </Typography>
                  </Stack>
                }
                secondary={
                  <>
                    <Typography
                      variant="body2"
                      color={getColorForResult(article)}>
                      {`Nové množstvo: ${article.newQuantity} | Rozdiel: ${article.newQuantity - article.oldQuantity}`}
                    </Typography>
                    <Typography variant="caption">{`Uložené množstvo: ${article.oldQuantity}`}</Typography>
                  </>
                }
              />
            </ListItem>
            <Divider variant="middle" />
          </React.Fragment>
        ))}
      </List>
    );
  };

  return (
    <Box>
      <Paper square sx={{ p: 2, mx: -2 }}>
        <Typography textAlign={"center"} variant="h6">
          {getLabel()}
        </Typography>
        {isResultStep && (
          <Typography textAlign={"center"} variant="body1">
            Výsledok inventúry
          </Typography>
        )}
        <MobileStepper
          sx={{
            pt: 2,
            backgroundColor: "transparent",
          }}
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={disabledNextButton()}>
              {getNextButtonLabel()}
              {!isResultStep && <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep < 2}>
              {<KeyboardArrowLeft />}
              Späť
            </Button>
          }
        />
      </Paper>
      <Box pt={2}>
        {activeStep === 0 && (
          <Stack spacing={2}>
            <Typography variant="body1" textAlign={"center"}>
              Pred začatím inventúry vyberte sklad, ktorý chcete inventarizovať.
            </Typography>
            <Select
              label="Skladu *"
              fullWidth
              value={warehouseId ?? ""}
              onChange={handleWarehouseChange}
              disabled={warehouses.length === 0}>
              {warehouses.map((warehouse) => (
                <MenuItem key={warehouse.id} value={warehouse.id}>
                  {warehouse.name}
                </MenuItem>
              ))}
            </Select>
            <Divider />
            <CustomerSection
              label="Inventúra - výdajka od"
              customerId={inventoryFrom}
              onChangeCustomerId={handleInventoryFromChange}
              type={CustomerType.SUPPLIER}
              showAddress={false}
            />
            <CustomerSection
              label="Inventúra - príjemka do"
              customerId={inventoryTo}
              onChangeCustomerId={handleInventoryToChange}
              type={CustomerType.BUYER}
              showAddress={false}
            />
            <Select
              label="Mena *"
              fullWidth
              value={currencyId ?? ""}
              onChange={handleCurrencyChange}
              disabled={currencies.length === 0}>
              {currencies.map((currency) => (
                <MenuItem key={currency.id} value={currency.id}>
                  {currency.name}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        )}
        {activeStep !== 0 && activeStep > 0 && !isResultStep && getArticleContent()}
        {isResultStep && getResult()}
      </Box>
    </Box>
  );
};

export default Inventory;
