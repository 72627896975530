import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { IArticlesState } from "store/redux/types";


const initialState: IArticlesState = {
  articles: [],
  loadTimestamp: undefined,
};

export const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    setArticles: (state, action: PayloadAction<IArticlesState["articles"]>) => {
      state.articles = action.payload;
      state.loadTimestamp = new Date().getTime();
    }
  },
});

export const { setArticles } = articlesSlice.actions;

export const articles = (state: RootState) => state.articles.articles;

export default articlesSlice.reducer;
