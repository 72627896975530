import { Box, CircularProgress, Container, Toolbar } from "@mui/material";
import { AppContext } from "context/AppContext";
import useAppInit from "hooks/useAppInit";
import { Outlet } from "react-router-dom";

import AppBar from "./AppBar";
import BottomNavigation from "./BottomNavigation";

const Main = () => {
  const { isReadyToRender, reaload } = useAppInit();

  if (!isReadyToRender) {
    return (
      <Box display={"flex"} justifyContent={"center"} height={"100vh"} alignItems={"center"}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar reaload={reaload} />
      <AppContext.Provider value={{ reaload }}>
        <Container sx={{ mb: 8 }} component="main" maxWidth="xs">
          <Toolbar />
          <Outlet />
        </Container>
      </AppContext.Provider>
      <BottomNavigation />
    </Box>
  );
};

export default Main;
